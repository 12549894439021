<style>
  main {
    background: #7fc5bb;
    height: 100%;
    overflow: auto;
  }
</style>

<!-- routify:options index=0 -->
<main>
  <br />
  <h1>Home</h1>
</main>
