<style>
  main {
    background: #88F0D0;
    height: 100%;
  }
</style>

<!-- routify:options index=2 -->


<main>
  <br />
  <h1>Updates</h1>
</main>
